/* $summernote
 ------------------------------------------*/

.note-editor {
  border-color: $border-color;
  @include rounded($border-radius-base);
  overflow: visible;
  .note-toolbar {
    margin-left: 0;
    padding: 0;
  }
  .note-toolbar .btn-group {
    margin: 0;
  }
  .btn-group .btn {
    @include rounded(0);
    @include box-shadow(none);
    height: 2.5rem;
    line-height: 2.5rem;
    min-width: 2.5rem;
    padding: 0 0.625rem;
    background-color: #f5f5f5;
    border-top: 0;
    border-bottom-color: $border-color;
    border-left: 0;
    border-right-color: $border-color;
    color: $text-color;
    &.active, &:hover {
      background-color: #ececec;
      &::before {
        @include box-shadow(none);
      }
    }
  }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group {
    margin-left: 0;
  }
  .note-toolbar {
    border-color: $border-color;
    border-bottom: 0;
    padding: 0;
  }
  .dropdown-menu {
    margin: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .note-editable {
    background-color: white;
    min-height: 9.375rem;
  }
}
