/* $labelauty
 ------------------------------------------*/
input.labelauty + label
{
  padding: 0.5625rem 0.625rem;
  background-color: $brand-default;
  color: $text-color;
  @include rounded($border-radius-base);
}
input.labelauty:not(:checked):not([disabled]) + label:hover
{
  background-color: darken($brand-default, 2%);
}
input.labelauty:checked + label
{
  background-color: $brand-primary;
  color: white;
}
input.labelauty:checked:not([disabled]) + label:hover
{
  background-color: darken($brand-primary, 5%);
}
input.btn-block + label
{
  display: block;
  width: 100%;
}
input.to-labelauty-icon + label {
    display: inline-block;
    margin-right: 0.3125rem;
}