/* $wizard
 ------------------------------------------*/

.wizard-pager {
  @include clearfix();
  margin-top: $padding-base;
  .btn {
    min-width: 6.25rem;
  }
}
