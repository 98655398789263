/* $bootstrap-wysiwyg
 ------------------------------------------*/

.bootstrap-wysiwyg .btn-toolbar {
  margin: 0;
  padding: 0 0 0.3125rem 0.3125rem;
  border: 0.0625rem solid $border-color;
  border-bottom: 0;
  .btn-group {
    margin-top: 0.3125rem;
    margin-right: 0.3125rem;
  }
}

ul.wysihtml5-toolbar {
  background: #f5f5f5;
  border: 0.0625rem solid $border-color;
  border-bottom: 0;
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;
  > li {
    margin: 0;
  }
  .btn {
    height: 2.5rem;
    line-height: 2.5rem;
    min-width: 2.5rem;
    padding: 0 0.625rem;
    background-color: #f5f5f5;
    border-top: 0;
    border-bottom-color: $border-color;
    border-left: 0;
    border-right-color: $border-color;
    color: $text-color;
    @include rounded(0);
    @include box-shadow(none);
    &:hover {
      background-color: #ececec;
    }
  }
  .btn-group .btn + .btn {
    margin-left: 0;
  }
  a.btn.wysihtml5-command-active {
    @include box-shadow(none);
    background-color: #ececec;
  }
  .open > .dropdown-toggle.btn {
    color: $text-color;
    background-color: #f5f5f5;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: $border-color;
  }
  .dropdown-menu {
    margin: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.wysihtml5-sandbox {
  padding: $padding-base!important;
  width: 100%!important;
  border-color: $border-color!important;
  border-top: 0!important;
  background-color: white;
  border-bottom-left-radius: $border-radius-base!important;
  border-bottom-right-radius: $border-radius-base!important;
  outline: none!important;
}
