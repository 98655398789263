/* $tables
 ------------------------------------------*/

.table {
  background-color: white;
}

.table {
  > thead, > tbody, > tfoot {
    > tr {
      > th, > td {
        padding: 0.625rem $padding-base;
        border-color: $table-border-color;
      }
    }
  }
  &.table-condensed {
    > thead, > tbody, > tfoot {
      > tr {
        > th, > td {
          padding: 0.35rem $padding-base;
        }
      }
    }
  }
  > thead > tr > th {
    border-bottom-width: 0;
  }
  > thead > tr > th,
  > tfoot > tr > th {
    border-color: $table-border-color;
    font-weight: $font-weight-base;
    text-transform: uppercase;
  }
}

.table-hover {
  > tbody > tr:hover {
    background-color: $table-bg-hover;
  }
}

.table-striped {
  > tbody > tr:nth-child(even) {
    background-color: $table-bg-accent;
  }
}

.table-bordered {
  border-color: $table-border-color;
  > thead, > tbody, > tfoot {
    > tr {
      > th, > td {
        border-color: $table-border-color;
        border-top: 0;
        border-left: 0;
        &:first-child {
          border-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  > thead > tr {
    > th, > td {
      border-bottom-width: 1px;
    }
  }
}

.table-striped {
  > tbody > tr:nth-child(odd) {
    background-color: transparent;
  }
}

.table-responsive {
  overflow-x: visible;
}

@media (max-width: $screen-xs-max) {
  .table-responsive {
    overflow-x: auto;
    border-color: $border-color;
    @include rounded($border-radius-base);
    margin-bottom: 0;
  }
}
/* No More tables */

@media (max-width: $screen-xs-max) {
  /* Source:: http://elvery.net/demo/responsive-tables/#no-more-tables */
  /* Force table to not be like tables anymore */

  .no-more-tables table,
  .no-more-tables thead,
  .no-more-tables tbody,
  .no-more-tables th,
  .no-more-tables td,
  .no-more-tables tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */

  .no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .no-more-tables tr {
    border: 0.0625rem solid $table-border-color;
  }
  .no-more-tables td {
    position: relative;
    padding-left: 50%!important;
    /* Behave  like a "row" */

    border-top: 0!important;
    border-bottom: 0.0625rem solid $table-border-color;
    text-align: left;
    white-space: normal;
  }
  .no-more-tables td:before {
    /* Now like a table header */

    position: absolute;
    /* Top/left values mimic padding */

    top: 0.375rem;
    left: 0.375rem;
    padding-right: 0.625rem;
    width: 45%;
    text-align: left;
    white-space: nowrap;
    font-weight: $font-weight-bold;
  }
  /* Label the data */

  .no-more-tables td:before {
    content: attr(data-title);
  }
}

@media (max-width: $screen-xs-max) {

  .flip-scroll .cf:after { visibility: hidden; display: block; font-size: 0; content: " "; clear: both; height: 0; }
  .flip-scroll * html .cf { zoom: 1; }
  .flip-scroll *:first-child+html .cf { zoom: 1; }

  .flip-scroll table { width: 100%; border-collapse: collapse; border-spacing: 0; }

  .flip-scroll th,
  .flip-scroll td { margin: 0; vertical-align: top; }
  .flip-scroll th { text-align: left; }

  .flip-scroll table { display: block; position: relative; width: 100%; }
  .flip-scroll thead { display: block; float: left; }
  .flip-scroll tbody { display: block; width: auto; position: relative; overflow-x: auto; white-space: nowrap; }
  .flip-scroll thead tr { display: block; }
  .flip-scroll th { display: block; text-align: right; }
  .flip-scroll tbody tr { display: inline-block; vertical-align: top; }
  .flip-scroll td { display: block; min-height: 1.25em; text-align: left; }


  /* sort out borders */

  .flip-scroll th { border-bottom: 0; border-left: 0; }
  .flip-scroll td { border-left: 0; border-right: 0; border-bottom: 0; }
  .flip-scroll tbody tr { border-left: 0.0625rem solid #babcbf; }
  .flip-scroll th:last-child,
  .flip-scroll td:last-child { border-bottom: 0.0625rem solid #babcbf; }
}