/* $session
 ------------------------------------------*/
 .session-wrapper {
    min-height: 100%;
    margin: 0 auto  -$footer-height;
    @include clearfix();
    &::after {
      height: $footer-height;
    }
 }
 .push {
  height: $footer-height;
 }
.app.signin .card,
.app.signup .card,
.app.forgot-password .card,
.app.error-page .card,
.app.lockscreen .card {
  @include box-shadow(0 0.125rem 0.25rem rgba(50, 70, 75, 0.2));
  display: block;
  margin: 100px auto;
  width: 100%;
}
.app.error-page .card {
  @include box-shadow(none);
}
.app.lockscreen .card {
  max-width: 400px;
}
.app.forgot-password .card,
.app.signin .card {
  max-width: 500px;
  .bottom-link {
    position: absolute;
    bottom: -30px;
    left: 5px;
  }
}
.app.signup .card {
  max-width: 600px;
}

.app.signin.v2,
.app.signup.v2 {
  .session-wrapper::after {
    height: 0;
  }
}
@media screen and (min-width: $screen-sm-min) {
  .app.signin.v2,
  .app.signup.v2 {
    .session-carousel {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: 0;
      width: 100%;
      width: -webkit-calc(100% - 500px);
      width: calc(100% - 500px);
      .carousel-inner {
        width: 100%;
        height: 100%;
      }
    }
    .carousel-inner > .item {
      width: 100%;
      height: 100%;
    }
    .card {
      margin: 0;
      float: right;
      min-height: 100vh;
      @include box-shadow(none);
      .card-block {
        padding-top: 150px
      }
    }
  }
}
@media (max-width: $screen-xs-max) {
  .app.signin.v2,
  .app.signup.v2 {
    .card {
      max-width: 100%;
      min-height: 100vh;
      margin: 0;
      .card-block {
        padding-top: 150px
      }
    }
    .push {
      display: none;
    }
  }
}

.usersession {
  //height: 100%;
}
.divider {
    border-top: 0.0625rem solid $border-color;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 0;
    span {
          display: inline-block;
    position: relative;
    padding: 0 $gutter;
    background: #fff;
    top: -10px;
    }
}

.form-layout {
  margin: 0 auto;
  padding: $padding-base;
  input {
    margin-bottom: $gutter;
  }
  label {
    width: 100%;
    display: block;
  }
  .name {
    input {
      float: left;
      display: block;
      width: 100%;
      width: -webkit-calc(50% - #{$gutter}/2);
      width: calc(50% - #{$gutter}/2);
      &.first{
        margin-right: $gutter;
      }
    }
  }
  .btn-lg {
    @include rounded($border-radius-base);
  }
}
