/* $breadcrumbs
 ------------------------------------------*/

.breadcrumb {
  background-color: $breadcrumb-bg;
  @include rounded($border-radius-base);
  > li + li::before {
    padding: 0 0.3125em;
    content: "#{$breadcrumb-separator}";
    font-family: $fontawesome-family-icon;
  }
}
