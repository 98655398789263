/* $invoice
 ------------------------------------------*/

.invoice-logo {
  height: 50px
}

.invoice-totals {
  margin-bottom: 15px;
  text-align: right;
  @include clearfix();
  .invoice-totals-row {
    margin-bottom: 5px;
  }
  .invoice-totals-title {
    margin-right: 15px;
  }
  .invoice-totals-value {
    display: inline-block;
    min-width: 90px;
    text-align: left;
  }
}
