/* $clock-picker
 ------------------------------------------*/

.clockpicker-popover {
  .popover-title {
    color: $text-color;
    font-weight: $font-weight-bold;
    font-size: 1rem;
    line-height: 1;
    span {
      cursor: pointer;
    }
  }
  .popover-content {
    background-color: white;
    &:last-child {
      border-bottom-right-radius: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
    }
  }
  .clockpicker-plate {
    border: 0.0625rem solid $border-color;
  }
  .clockpicker-button {
    margin: 0;
    padding: 0.625rem 0;
    border-width: 0.0625rem 0 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
