/* $webfonts
 ------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-heading-webfont;
}

dt,
.bold {
  font-weight: $font-weight-bold;
}
