/* $preloader
 ------------------------------------------*/
.pageload {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  background-color: transparentize(white, 0.01);
  z-index: 99999;
  display: table;
  > div {
    display: table-cell;
    vertical-align: middle;
    > div {
      margin: 0 auto;
    }
  }
}

.page-loading {
  overflow: hidden;
}

.preloader-container {
  position: absolute;
  right: 0;
  left: 0;
}
.layout-fixed-header .preloader-container {
  left: ($sidebar-width);
}
.layout-small-menu .preloader-container {
  left: ($sidebar-small-menu-width - 1.188rem);
}
.preloader {
  display: table;
  margin: 0 auto;
  @include vertical-align-padding($header-height, 0.375rem);
  -webkit-animation: 2s bulge infinite ease-out;
          animation: 2s bulge infinite ease-out;
}
.preloader span {
  @include square(6px);
  float: left;
  margin: 0 2px;
  background-color: lighten($brand-primary, 30%);
  display: block;
  @include rounded(50%);
  @include opacity(0.4);
}
.preloader span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
          animation: 1s blink infinite 0.3333s;
}
.preloader span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
          animation: 1s blink infinite 0.6666s;
}
.preloader span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
          animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
  50% {
    @include opacity(1);
  }
}

@keyframes blink {
  50% {
    @include opacity(1);
  }
}
@-webkit-keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}
@keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}
@media (max-width: $screen-xs-max) {
  .preloader-container {
    left: 0;
  }
}