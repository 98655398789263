/* $avatar
 ------------------------------------------*/

.avatar {
  position: relative;
  display: inline-block;
}

.img-thumbnail {
  border-color: $border-color;
}

/* sizes */
.avatar-xlg {
  width: 32rem;
}

.avatar-xlg {
  width: 16rem;
}

.avatar-lg {
  width: 8rem;
}

.avatar-md {
  width: 4rem;
}

.avatar-sm {
  width: 3rem;
}

.avatar-xs {
  width: 2.125rem;
}

.avatar-xxs {
  width: 1.375rem;
}


/*
 * Image $Captions
 *
 */

figure {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: $padding-base;
  img {
    width: 100%;
  }
}

figcaption {
  position: absolute;
  background: $brand-dark;
  background: transparentize($brand-dark, 0.25);
  color: white;
  padding: $padding-base $padding-base-horizontal;
  @include opacity(0);
  @include transition(all 0.6s ease);
}

figure:hover figcaption {
  @include opacity(1);
}

figure:before {
  content: "?";
  position: absolute;
  background: $brand-white;
  background: transparentize($brand-white, 0.25);
  color: $text-color;
  @include square(1.5rem);
  @include rounded(50%);
  text-align: center;
  line-height: 1.5rem;
  @include transition(all 0.6s ease);
  @include opacity(.8);
}

figure:hover:before {
  @include opacity(0);
}

.cap-left:before {
  bottom: $padding-base;
  left: $padding-base;
}

.cap-left figcaption {
  bottom: 0;
  left: -30%;
}

.cap-left:hover figcaption {
  left: 0;
}

.cap-right:before {
  bottom: $padding-base;
  right: $padding-base;
}

.cap-right figcaption {
  bottom: 0;
  right: -30%;
}

.cap-right:hover figcaption {
  right: 0;
}

.cap-top:before {
  top: $padding-base;
  left: $padding-base;
}

.cap-top figcaption {
  left: 0;
  top: -30%;
}

.cap-top:hover figcaption {
  top: 0;
}

.cap-bot:before {
  bottom: $padding-base;
  left: $padding-base;
}

.cap-bot figcaption {
  left: 0;
  bottom: -30%;
}

.cap-bot:hover figcaption {
  bottom: 0;
}
