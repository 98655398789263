/* $chartjs
 ------------------------------------------*/

.canvas-holder {
  padding: 0;
  margin: 0;
  position: relative;
}

.doughnut-legend {
  list-style: none;
  position: absolute;
  right: 0.5rem;
  top: 0;
  li {
    display: block;
    padding-left: 1.875rem;
    position: relative;
    margin-bottom: 0.25rem;
    padding: 0.125rem 0.5rem 0.125rem 1.75rem;
    cursor: default;
    @include transition(background-color 200ms ease-in-out);
  }
  li:hover {
    background-color: $brand-default;
  }
  li span {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1.25rem;
    height: 100%;
  }
}
