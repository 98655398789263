/* $timeline
 ------------------------------------------*/

.timeline {
  position: relative;
  margin-bottom: 3.25rem;
  &::before {
    position: absolute;
    top: 6px;
    left: 15px;
    width: 2px;
    height: 100%;
    background: $timeline-border-color;
    content: '';
  }
  .timeline-icon {
    position: absolute;
    top: 6px;
    left: 0;
    text-align: center;
    @include square(1.875rem);
    @include rounded(50%);
    @include box-shadow(0 0 0 2px #fff);
    > i {
      line-height: 1.875rem;
      font-size: 13px;
    }
  }
  .timeline-card {
    position: relative;
    margin: 1.875rem 0;
    @include clearfix();
  }
  .timeline-card:first-child {
    margin-top: 0;
  }
  .timeline-heading {
    @include clearfix();
    position: relative;
    border-color: transparent;
    margin-bottom: $padding-base;
    &.border:after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 0.0625rem solid transparentize(black, 0.94);
    }
  }
  .timeline-content {
    position: relative;
    margin-left: 60px;
    padding: $padding-base;
    border: 0.0625rem solid $timeline-border-color;
    background: white;
    @include rounded($border-radius-base);
    @include clearfix();
  }
  .timeline-content .timeline-date {
    display: inline-block;
    padding: 5px 0 10px;
  }
  .timeline-content::before {
    position: absolute;
    top: 13px;
    right: 100%;
    @include square(0);
    border-width: 7px;
    border-style: solid;
    border-color: transparent $timeline-border-color transparent transparent;
    content: " ";
    pointer-events: none;
  }
  .timeline-content::after {
    position: absolute;
    top: 14px;
    right: 100%;
    @include square(0);
    border-width: 6px;
    border-style: solid;
    border-color: transparent white transparent transparent;
    content: " ";
    pointer-events: none;
  }
}

@media screen and (min-width: $screen-sm-min) {
  .timeline:not(.stacked) {
    &::before {
      left: 50%;
      margin-left: -1px;
    }
    .timeline-card:nth-child(even) .timeline-content {
      float: right;
    }
    .timeline-card:nth-child(odd) .timeline-content::before {
      position: absolute;
      right: auto;
      left: 100%;
      border-color: transparent transparent transparent $timeline-border-color;
    }
    .timeline-card:nth-child(odd) .timeline-content::after {
      position: absolute;
      right: auto;
      left: 100%;
      border-color: transparent transparent transparent white;
    }
    .timeline-card:nth-child(even) .timeline-content .timeline-date {
      right: 122%;
      left: auto;
      text-align: right;
    }
    .timeline-card:first-child {
      margin-top: 0;
    }
    .timeline-icon {
      left: 50%;
      margin-left: -15px;
    }
    .timeline-content {
      margin-left: 0;
      padding: 20px;
      width: 45%;
    }
    .timeline-content .timeline-date {
      position: absolute;
      top: 6px;
      left: 122%;
      width: 100%;
    }
  }
}
