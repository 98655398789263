/* $animation
 ------------------------------------------*/
 /* Generated with Bounce.js. Edit at http://goo.gl/akZHSq */

@-webkit-keyframes animSlideElasticLeft {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1000, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1000, 0, 0, 1);
  }
  1.3% {
    -webkit-transform: matrix3d(1.83, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -790.068, 0, 0, 1);
    transform: matrix3d(1.83, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -790.068, 0, 0, 1);
  }
  2.55% {
    -webkit-transform: matrix3d(2.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -609.326, 0, 0, 1);
    transform: matrix3d(2.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -609.326, 0, 0, 1);
  }
  4.1% {
    -webkit-transform: matrix3d(1.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -419.708, 0, 0, 1);
    transform: matrix3d(1.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -419.708, 0, 0, 1);
  }
  5.71% {
    -webkit-transform: matrix3d(1.583, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -265.321, 0, 0, 1);
    transform: matrix3d(1.583, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -265.321, 0, 0, 1);
  }
  8.11% {
    -webkit-transform: matrix3d(1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -105.491, 0, 0, 1);
    transform: matrix3d(1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -105.491, 0, 0, 1);
  }
  8.81% {
    -webkit-transform: matrix3d(1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -72.799, 0, 0, 1);
    transform: matrix3d(1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -72.799, 0, 0, 1);
  }
  11.96% {
    -webkit-transform: matrix3d(1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 16.084, 0, 0, 1);
    transform: matrix3d(1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 16.084, 0, 0, 1);
  }
  12.11% {
    -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 18.434, 0, 0, 1);
    transform: matrix3d(1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 18.434, 0, 0, 1);
  }
  15.07% {
    -webkit-transform: matrix3d(0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 42.205, 0, 0, 1);
    transform: matrix3d(0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 42.205, 0, 0, 1);
  }
  16.12% {
    -webkit-transform: matrix3d(0.986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 43.356, 0, 0, 1);
    transform: matrix3d(0.986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 43.356, 0, 0, 1);
  }
  27.23% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.839, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.839, 0, 0, 1);
  }
  27.58% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.069, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.069, 0, 0, 1);
  }
  38.34% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1.037, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1.037, 0, 0, 1);
  }
  40.09% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.97, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.97, 0, 0, 1);
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.159, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.159, 0, 0, 1);
  }
  60.56% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.025, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.025, 0, 0, 1);
  }
  82.78% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes animSlideElasticLeft {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1000, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1000, 0, 0, 1);
  }
  1.3% {
    -webkit-transform: matrix3d(1.83, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -790.068, 0, 0, 1);
    transform: matrix3d(1.83, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -790.068, 0, 0, 1);
  }
  2.55% {
    -webkit-transform: matrix3d(2.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -609.326, 0, 0, 1);
    transform: matrix3d(2.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -609.326, 0, 0, 1);
  }
  4.1% {
    -webkit-transform: matrix3d(1.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -419.708, 0, 0, 1);
    transform: matrix3d(1.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -419.708, 0, 0, 1);
  }
  5.71% {
    -webkit-transform: matrix3d(1.583, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -265.321, 0, 0, 1);
    transform: matrix3d(1.583, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -265.321, 0, 0, 1);
  }
  8.11% {
    -webkit-transform: matrix3d(1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -105.491, 0, 0, 1);
    transform: matrix3d(1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -105.491, 0, 0, 1);
  }
  8.81% {
    -webkit-transform: matrix3d(1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -72.799, 0, 0, 1);
    transform: matrix3d(1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -72.799, 0, 0, 1);
  }
  11.96% {
    -webkit-transform: matrix3d(1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 16.084, 0, 0, 1);
    transform: matrix3d(1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 16.084, 0, 0, 1);
  }
  12.11% {
    -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 18.434, 0, 0, 1);
    transform: matrix3d(1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 18.434, 0, 0, 1);
  }
  15.07% {
    -webkit-transform: matrix3d(0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 42.205, 0, 0, 1);
    transform: matrix3d(0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 42.205, 0, 0, 1);
  }
  16.12% {
    -webkit-transform: matrix3d(0.986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 43.356, 0, 0, 1);
    transform: matrix3d(0.986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 43.356, 0, 0, 1);
  }
  27.23% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.839, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.839, 0, 0, 1);
  }
  27.58% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.069, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.069, 0, 0, 1);
  }
  38.34% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1.037, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -1.037, 0, 0, 1);
  }
  40.09% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.97, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.97, 0, 0, 1);
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.159, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.159, 0, 0, 1);
  }
  60.56% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.025, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.025, 0, 0, 1);
  }
  82.78% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.001, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}


/* Generated with Bounce.js. Edit at http://goo.gl/tf9GOc */

@-webkit-keyframes animSlideElasticRight {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1);
  }
  1.3% {
    -webkit-transform: matrix3d(1.83, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 790.068, 0, 0, 1);
    transform: matrix3d(1.83, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 790.068, 0, 0, 1);
  }
  2.55% {
    -webkit-transform: matrix3d(2.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 609.326, 0, 0, 1);
    transform: matrix3d(2.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 609.326, 0, 0, 1);
  }
  4.1% {
    -webkit-transform: matrix3d(1.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 419.708, 0, 0, 1);
    transform: matrix3d(1.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 419.708, 0, 0, 1);
  }
  5.71% {
    -webkit-transform: matrix3d(1.583, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 265.321, 0, 0, 1);
    transform: matrix3d(1.583, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 265.321, 0, 0, 1);
  }
  8.11% {
    -webkit-transform: matrix3d(1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 105.491, 0, 0, 1);
    transform: matrix3d(1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 105.491, 0, 0, 1);
  }
  8.81% {
    -webkit-transform: matrix3d(1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 72.799, 0, 0, 1);
    transform: matrix3d(1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 72.799, 0, 0, 1);
  }
  11.96% {
    -webkit-transform: matrix3d(1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -16.084, 0, 0, 1);
    transform: matrix3d(1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -16.084, 0, 0, 1);
  }
  12.11% {
    -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -18.434, 0, 0, 1);
    transform: matrix3d(1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -18.434, 0, 0, 1);
  }
  15.07% {
    -webkit-transform: matrix3d(0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -42.205, 0, 0, 1);
    transform: matrix3d(0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -42.205, 0, 0, 1);
  }
  16.12% {
    -webkit-transform: matrix3d(0.986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -43.356, 0, 0, 1);
    transform: matrix3d(0.986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -43.356, 0, 0, 1);
  }
  27.23% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.839, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.839, 0, 0, 1);
  }
  27.58% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.069, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.069, 0, 0, 1);
  }
  38.34% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.037, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.037, 0, 0, 1);
  }
  40.09% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.97, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.97, 0, 0, 1);
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.159, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.159, 0, 0, 1);
  }
  60.56% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.025, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.025, 0, 0, 1);
  }
  82.78% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.001, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.001, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes animSlideElasticRight {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1);
  }
  1.3% {
    -webkit-transform: matrix3d(1.83, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 790.068, 0, 0, 1);
    transform: matrix3d(1.83, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 790.068, 0, 0, 1);
  }
  2.55% {
    -webkit-transform: matrix3d(2.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 609.326, 0, 0, 1);
    transform: matrix3d(2.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 609.326, 0, 0, 1);
  }
  4.1% {
    -webkit-transform: matrix3d(1.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 419.708, 0, 0, 1);
    transform: matrix3d(1.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 419.708, 0, 0, 1);
  }
  5.71% {
    -webkit-transform: matrix3d(1.583, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 265.321, 0, 0, 1);
    transform: matrix3d(1.583, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 265.321, 0, 0, 1);
  }
  8.11% {
    -webkit-transform: matrix3d(1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 105.491, 0, 0, 1);
    transform: matrix3d(1.234, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 105.491, 0, 0, 1);
  }
  8.81% {
    -webkit-transform: matrix3d(1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 72.799, 0, 0, 1);
    transform: matrix3d(1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 72.799, 0, 0, 1);
  }
  11.96% {
    -webkit-transform: matrix3d(1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -16.084, 0, 0, 1);
    transform: matrix3d(1.01, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -16.084, 0, 0, 1);
  }
  12.11% {
    -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -18.434, 0, 0, 1);
    transform: matrix3d(1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -18.434, 0, 0, 1);
  }
  15.07% {
    -webkit-transform: matrix3d(0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -42.205, 0, 0, 1);
    transform: matrix3d(0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -42.205, 0, 0, 1);
  }
  16.12% {
    -webkit-transform: matrix3d(0.986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -43.356, 0, 0, 1);
    transform: matrix3d(0.986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -43.356, 0, 0, 1);
  }
  27.23% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.839, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.839, 0, 0, 1);
  }
  27.58% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.069, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.069, 0, 0, 1);
  }
  38.34% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.037, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.037, 0, 0, 1);
  }
  40.09% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.97, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.97, 0, 0, 1);
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.159, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.159, 0, 0, 1);
  }
  60.56% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.025, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.025, 0, 0, 1);
  }
  82.78% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.001, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.001, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@-webkit-keyframes animSlideRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(100%, 0, 0);
  }
}

@keyframes animSlideRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes animSlideLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
  }
}

@keyframes animSlideLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0) translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}