.chocolat-wrapper {
  z-index: 10000;
}
.chocolat-loader {
  @include square(24px);
  background-image: none;
  background-color: $brand-primary;
  margin-top: -12px;
  margin-left: -12px;
  -webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
  animation: sk-rotatePlane 1.2s infinite ease-in-out;
}
