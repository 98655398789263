/* $labels
 ------------------------------------------*/

.label {
  @include rounded(0.0625rem);
}

.label-default {
  @include label-variant($label-default-bg);
  color: $text-color;
}

.label-primary {
  @include label-variant($label-primary-bg);
}

.label-success {
  @include label-variant($label-success-bg);
}

.label-info {
  @include label-variant($label-info-bg);
}

.label-warning {
  @include label-variant($label-warning-bg);
}

.label-danger {
  @include label-variant($label-danger-bg);
}
