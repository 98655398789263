/* $sortable
 ------------------------------------------*/

.sortable-list {
  padding: 0;
  -webkit-touch-callout: none;
  @include user-select(none);
  li {
    position: relative;
    display: block;
    margin-bottom: -0.0625rem;
    padding: 0.9375rem;
    border: 0.0625rem solid $border-color;
    background-color: #fff;
    cursor: move;
    &:first-child {
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
    }
    &:last-child {
      border-bottom-right-radius: $border-radius-base;
      border-bottom-left-radius: $border-radius-base;
    }
  }
  li.ui-state-highlight {
    min-height: 3.125rem;
    width: 100%;
    background: transparentize(black, 0.99);
    list-style: none;
  }
}
