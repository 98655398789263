/* $navbar
 ------------------------------------------*/
.navbar {
  min-height: 0;
}
/* Navbar inverse */

.navbar-inverse {
  border-color: $navbar-inverse-border;
  background-color: $navbar-inverse-bg;
  .navbar-brand, .navbar-nav > li > a {
    color: $navbar-inverse-color;
  }
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:hover,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus {
    color: white;
    background-color: darken($navbar-inverse-bg, 3%);
  }
  .navbar-collapse,
  .navbar-form {
    border-color: $navbar-inverse-border;
  }
  .navbar-nav .open .dropdown-menu .divider {
    background-color: darken($navbar-inverse-border, 5%);
  }
  .dropdown-menu {
    border-color: $navbar-inverse-border;
  }
}
