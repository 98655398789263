/* $lockscreen
 ------------------------------------------*/

.lockcode {
  display: block;
  margin: 0 auto;
  max-width: 280px;
}

.lockscreen-avatar {
  position: relative;
  max-width: 128px;
  margin: 0 auto;
}



