/* $layout-options
 ------------------------------------------*/

/* Fixed header */
.layout-fixed-header {
  .main-panel > .header {
    position: fixed;
    top: 0;
  }
  .main-content {
    padding-top: ($header-height + $gutter);
    &.no-padding {
      padding-top: $header-height;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  /* Right sidebar positioning */
  .layout-right-sidebar {
    .sidebar-panel {
      right: 0;
    }
    .main-panel {
      margin-left: 0;
      margin-right: $sidebar-width;
    }
    &.layout-small-menu .sidebar-panel nav > ul > li > .sub-menu {
      right: 100%!important;
      left: auto!important;
    }
    &.layout-small-menu .main-panel {
      margin-left: 0;
      margin-right: $sidebar-small-menu-width;
    }
    .fill-container {
      left: 0;
      right: $sidebar-width;
    }
    &.layout-small-menu .fill-container {
      right: 0;
      right: $sidebar-small-menu-width;
    }
  }

  /* Static sidebar for static menu, small menu, and boxed menu */
  .layout-static-sidebar,
  .layout-small-menu,
  .layout-boxed {
    overflow: hidden;
    .sidebar-panel {
      position: absolute;
      > nav {
        position: static;
        top: 0;
        right: auto;
        bottom: auto;
        left: auto;
        overflow: visible;
        display: block;
      }
    }
  }
  .layout-boxed {
    @include box-shadow(0 0 0.3125rem rgba(0, 0, 0, .2));
  }
  .layout-static-sidebar,
  .layout-boxed {
    .full-height-content {
      position: static;
      height: 100%;
    }
    .full-height-content .fill-container {
      width: auto;
      height: auto;
      top: $header-height;
      bottom: $footer-height;
    }
    &.layout-fixed-header .full-height-content {
      margin-top: $header-height;
    }
  }

  /* Fixed header */
  .layout-fixed-header {
    .main-panel > .header {
      padding-left: $sidebar-width;
      margin-left: -$sidebar-width;
    }
    &.layout-small-menu {
      .main-panel > .header {
        padding-left: $sidebar-small-menu-width;
        margin-left: -$sidebar-small-menu-width;
      }
    }
    .full-height-content {
      top: $header-height;
    }
  }
  .layout-fixed-header.layout-right-sidebar {
    .main-panel > .header {
      padding-left: 0;
      padding-right: $sidebar-width;
      margin-left: 0;
      margin-right: -$sidebar-width;
    }
    &.layout-small-menu {
      .main-panel > .header {
        padding-left: 0;
        padding-right: $sidebar-small-menu-width;
        margin-left: 0;
        margin-right: -$sidebar-small-menu-width;
      }
    }
  }

  /* Footer */
  footer.content-footer {
    padding-left: ($sidebar-width + $gutter);
    .layout-small-menu & {
      padding-left: ($sidebar-small-menu-width + $gutter);
    }
    .layout-right-sidebar & {
      padding-left: 0;
      padding-right: ($sidebar-width + $gutter);
    }
    .layout-small-menu.layout-right-sidebar & {
      padding-left: 0;
      padding-right: ($sidebar-small-menu-width + $gutter);
    }
  }
  .layout-fixed-footer {
    footer.content-footer {
      position: fixed;
      bottom: 0;
      background: $main-panel-bg;
      padding-left: ($sidebar-width + $gutter);
    }
    &.layout-small-menu {
      footer.content-footer {
        padding-left: ($sidebar-small-menu-width + $gutter);
      }
    }
  }
  .layout-fixed-footer.layout-right-sidebar {
    footer.content-footer {
      padding-left: 0;
      padding-right: ($sidebar-width + $gutter);
    }
    &.layout-small-menu {
      footer.content-footer {
        padding-left: 0;
        padding-right: ($sidebar-small-menu-width + $gutter);
      }
    }
  }
}
/* Right sidebar-panel */
.layout-right-sidebar .chat-panel {
  right: auto;
  left: 0;
  @include translate(-100%, 0);
  @include translate3d(-100%, 0, 0);
}

.layout-chat-open .chat-panel {
  @include translate(0, 0);
  @include translate3d(0, 0, 0);
}

@media (max-width: $screen-xs-max) {
  .layout-right-sidebar .brand .toggle-offscreen {
    left: auto;
    right: 0;
  }
  .layout-right-sidebar .brand .toggle-chat {
    left: 0;
    right: auto;
  }
}

/* Boxed layout widths */
.layout-boxed {
  margin-right: auto;
  margin-left: auto;
  .chat-panel {
    position: absolute;
  }
}

@media (min-width: 48em) {
  .layout-boxed,
  .layout-boxed .preloader {
    max-width: 46.88rem;
  }
  .layout-boxed.layout-fixed-header {
    .main-panel > .header {
      max-width: 46.88rem;
    }
  }
  .layout-boxed.layout-fixed-footer {
    footer.content-footer {
      max-width: 46.88rem;
    }
  }
}

@media (min-width: 62rem) {
  .layout-boxed,
  .layout-boxed .preloader {
    max-width: 60.63rem;
  }
  .layout-boxed.layout-fixed-header {
    .main-panel > .header {
      max-width: 60.63rem;
    }
  }
  .layout-boxed.layout-fixed-footer {
    footer.content-footer {
      max-width: 60.63rem;
    }
  }
}

@media (min-width: 75rem) {
  .layout-boxed,
  .layout-boxed .preloader {
    max-width: 73.13rem;
  }
  .layout-boxed.layout-fixed-header {
    .main-panel > .header {
      max-width: 73.13rem;
    }
  }
  .layout-boxed.layout-fixed-footer {
    footer.content-footer {
      max-width: 73.13rem;
    }
  }
}
