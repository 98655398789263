/* $modal
 ------------------------------------------*/

.modal {
  z-index: $zindex-modal;
  background: black;
  background: transparentize(black, 0.6);
}

.modal-content {
  @include box-shadow(none);
  @include rounded($border-radius-large);
}

.modal .modal-dialog,
.modal.in .modal-dialog {
  @include animation-duration(800ms);
  @include animation-timing-function(linear);
  @include animation-fill-mode(forwards);
}

.modal .modal-dialog {
  @include animation-name(animation-close);
  @include animation-duration($transition-duration);
  @include animation-timing-function(ease-out);
  @include translate(0, 0);
}

.modal.in .modal-dialog {
  @include animation-name(animation-open);
}
