/* $error-pages
 ------------------------------------------*/

.error-number {
  font-size: 150px;
  line-height: 1;
}

.error-icon {
  font-size: 100px;
  line-height: 160px;
  border: 3px solid $brand-danger;
  color: $brand-danger;
  width: 160px;
  height: 160px;
  @include rounded(50%);
  margin: 0 auto 20px;
}
