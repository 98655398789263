/* $popover
 ------------------------------------------*/

.popover {
  @include box-shadow(0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.05));
  font-family: inherit;
  font-size: inherit;
  @include rounded($border-radius-base);
  border-color: $border-color;
  padding: 0;
  .popover-title {
    border-bottom-color: $border-color;
    background-color: $popover-header;
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
  }
  .popover-content:last-child {
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
  }
}
