/* $charts
 ------------------------------------------*/
.labels-white .flot-tick-label{
  color: white;
}
.flot-x-axis > :first-child {
  display: none;
}
.dashboard-rickshaw {
  svg, .rickshaw_graph {
    @include rounded($border-radius-small);
    display: block;
  }
  > .rickshaw_graph {
    height: 100%
  }
}

.chart_container {
  position: relative;
}

.rickshaw-chart {
  position: relative;
}

.y_axis {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
}

.chart {
  width: 100%;
  //height: 320px;
  border: 0;
}

.chart-sm {
  width: 100%;
  height: 150px;
  border: 0;
}

.morrischart {
  width: 100%;
  height: 200px;
  border: 0;
}

.chart-legend.first {
  padding-top: 80px;
}

svg.nvd3-svg {
  width: 100%;
  display: block;
}

.c3chart {
  display: block;
  position: relative;
  width: 100%;
}
