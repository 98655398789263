/* $multiselect
 ------------------------------------------*/
select.center-block + .ms-container{
  margin: 0 auto $gutter;
}
.ms-container .ms-selectable, .ms-container .ms-selection{
  color: $text-color;
}
.ms-container .ms-list{
  @include box-shadow(none);
  border-color: $border-color;
  @include rounded($border-radius-base);
}
.ms-container .ms-list.ms-focus{
  border-color: $brand-primary;
  @include box-shadow(none);
}
.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection{
  border-bottom-color: $border-color;
  color: $text-color;
  font-size: $font-size-base;
}
.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover{
  background-color: $brand-primary;
  color: white;
}

.ms-container .ms-selectable li.disabled,
.ms-container .ms-selection li.disabled{
  background-color: $brand-default;
}

