/* $Utilities Classes */
$xxs: 2px;
$xs: 5px;
$sm: 10px;
$md: 15px;
$df: 1.25rem;
$lg: 25px;
$xlg: 30px;
.inline {
    display: inline-block;
    //vertical-align: top;
}
/* Margins */
.m-x-n {
    margin-right: -$padding-base!important;
    margin-left: -$padding-base!important;
}
.m-r-n {
    margin-right: -$padding-base!important;
}
.m-l-n {
    margin-left: -$padding-base!important;
}
.m-t-n {
    margin-top: -$padding-base!important;
}
.m-b-n {
    margin-bottom: -$padding-base!important;
}
//Gutter
.m-x-n-g {
    margin-right: -$gutter!important;
    margin-left: -$gutter!important;
}
.m-r-n-g {
    margin-right: -$gutter!important;
}
.m-l-n-g {
    margin-left: -$gutter!important;
}
.m-t-n-g {
    margin-top: -$gutter!important;
}
.m-b-n-g {
    margin-bottom: -$gutter!important;
}
/* no background color*/
.no-bg {
    background-color: transparent!important;
}
.shadow {
    @include box-shadow(0 0 5px rgba(0, 0, 0, .2));
}
.no-shadow {
    @include box-shadow(none);
}
.text-shadow {
    text-shadow: 0 0.0625rem 0.0625rem rgba(255,255,255,.9);
}
.align-middle td,
td.align-middle{
    vertical-align: middle!important;
}
/* Position */
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.lt {
    left: 0;
}
.rt {
    right: 0;
}
.bt {
    bottom: 0;
}
.tp {
    top: 0;
}
/* Radius */
.no-radius {
    @include rounded(0);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.round {
    @include rounded($border-radius-base);
}
.circle {
  @include rounded(50%);
}
.brtl {
    border-top-left-radius: $border-radius-base;
}
.brtr {
    border-top-right-radius: $border-radius-base;
}
.brbl {
    border-bottom-left-radius: $border-radius-base;
}
.brbr {
    border-bottom-right-radius: $border-radius-base;
}
/* Overflow */
.overflow-hidden {
    overflow: hidden;
}
.overflow-visible {
    overflow: visible;
}
.overflow-auto {
    overflow: auto;
}
.block {
    display: block;
}
.open .hover-inactive,
.hover-inactive {
    &:hover,
    &:focus{
        background-color: transparent!important;
    }
}
/* Widths */
.w100 {
    width: 100px;
}
.w150 {
    width: 150px;
}
.w200 {
    width: 200px;
}
.w250 {
    width: 250px;
}
.w300 {
    width: 300px;
}
.w350 {
    width: 350px;
}
.w400 {
    width: 400px;
}
.w450 {
    width: 400px;
}
.w500 {
    width: 500px;
}
/* Toggle */
.toggle-active {
    cursor: pointer;
}
.toggle-active.active {
    color: $brand-warning;
}
/* Borders */
.no-border,
.b-a-0
{
    border: 0;
}
.b-a {
    border: 0.0625rem solid $border-color;
}
.b-b {
    border-bottom: 0.0625rem solid $border-color;
}
.b-t {
    border-top: 0.0625rem solid $border-color;
}
.b-l {
    border-left: 0.0625rem solid $border-color;
}
.b-r {
    border-right: 0.0625rem solid $border-color;
}
.b-t-0 {
    border-top: 0;
}
.b-r-0 {
    border-right: 0;
}
.b-b-0 {
    border-bottom: 0;
}
.b-l-0 {
    border-left: 0;
}
.avatar.bordered {
    border: 3px solid fade(#fff, 50%);
}
/* $border color */
.border-primary {
    border-color: $brand-primary;
}
.border-default {
    border-color: $brand-default;
}
.border-warning {
    border-color: $brand-warning;
}
.border-success {
    border-color: $brand-success;
}
.border-info {
    border-color: $brand-info;
}
.border-white {
    border-color: $brand-white;
}
.border-danger {
    border-color: $brand-danger;
}
.full-height {
  height: 100%;
}
[data-animation] {
  visibility: hidden;
}
.animated {
  visibility: visible!important;
}
.visible {
  visibility: visible;
}
.scrollable {
  overflow: hidden;
  position: relative;
}
.scroll {
  overflow-x: hidden;
  overflow-y: auto;
}





















.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-xs-left {
  text-align: left;
}

.text-xs-right {
  text-align: right;
}

.text-xs-center {
  text-align: center;
}

@media (min-width: 34em) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 48em) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 62em) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 75em) {
  .text-xl-left {
    text-align: left;
  }
  .text-xl-right {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}

.m-a-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-a {
  margin: 1rem !important;
}

.m-t {
  margin-top: 1rem !important;
}

.m-r {
  margin-right: 1rem !important;
}

.m-b {
  margin-bottom: 1rem !important;
}

.m-l {
  margin-left: 1rem !important;
}

.m-x {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.m-y {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-a-md {
  margin: 1.5rem !important;
}

.m-t-md {
  margin-top: 1.5rem !important;
}

.m-r-md {
  margin-right: 1.5rem !important;
}

.m-b-md {
  margin-bottom: 1.5rem !important;
}

.m-l-md {
  margin-left: 1.5rem !important;
}

.m-x-md {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.m-y-md {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-a-lg {
  margin: 3rem !important;
}

.m-t-lg {
  margin-top: 3rem !important;
}

.m-r-lg {
  margin-right: 3rem !important;
}

.m-b-lg {
  margin-bottom: 3rem !important;
}

.m-l-lg {
  margin-left: 3rem !important;
}

.m-x-lg {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.m-y-lg {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-a-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-x-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-a {
  padding: 1rem !important;
}

.p-t {
  padding-top: 1rem !important;
}

.p-r {
  padding-right: 1rem !important;
}

.p-b {
  padding-bottom: 1rem !important;
}

.p-l {
  padding-left: 1rem !important;
}

.p-x {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.p-y {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-a-md {
  padding: 1.5rem !important;
}

.p-t-md {
  padding-top: 1.5rem !important;
}

.p-r-md {
  padding-right: 1.5rem !important;
}

.p-b-md {
  padding-bottom: 1.5rem !important;
}

.p-l-md {
  padding-left: 1.5rem !important;
}

.p-x-md {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.p-y-md {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-a-lg {
  padding: 3rem !important;
}

.p-t-lg {
  padding-top: 3rem !important;
}

.p-r-lg {
  padding-right: 3rem !important;
}

.p-b-lg {
  padding-bottom: 3rem !important;
}

.p-l-lg {
  padding-left: 3rem !important;
}

.p-x-lg {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.p-y-lg {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 33.9em) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 34em) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 47.9em) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 61.9em) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 62em) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 74.9em) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 75em) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print .hidden-print {
    display: none !important;
  }
}
