/* $header
 ------------------------------------------*/

.main-panel > .header {
  width: 100%;
  height: $header-height;
  z-index: $zindex-header;
  border: 0;
  margin: 0;
  @include rounded(0);
  /* Element alignments */
  .navbar-text {
    @include vertical-align($header-height, 1.188rem);
    margin-right: $padding-base;
    margin-left: $padding-base;
  }
  .navbar-btn {
    @include vertical-align($header-height, 2.125rem);
  }
  .btn-lg.navbar-btn {
    @include vertical-align($header-height, 2.813rem);
  }
  .btn-sm.navbar-btn {
    @include vertical-align($header-height, 1.875rem);
  }
  .btn-xs.navbar-btn {
    @include vertical-align($header-height, 1.375rem);
  }
  .navbar-nav {
    margin: 0;
    > li > a {
      @include vertical-align-padding($header-height, 1.188rem);
      max-height: $header-height;
    }
    .dropdown-menu {
      display: block;
      transform-origin: top right;
      right: 0;
      @include scale(0, 0);
      margin-top: 0;
      border-color: transparentize(black, 0.9);
      background-color: #f8f8f8;
      @include box-shadow(0 0.375rem 0.75rem transparentize(black, 0.9));
      @include rounded($border-radius-base);
      transition: transform 150ms ease-out;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      min-width: auto;
      &.notifications {
        min-width: 330px;
      }
    }
    .open .dropdown-menu {
      @include scale(1, 1);
    }
  }
  .nav > li > a:hover,
  .nav > li > a:focus {
    background-color: transparent;
  }
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: transparent;
  }
  .header-avatar {
    width: 1.5rem;
    margin-top: -0.1rem;
  }
  &::before {
    content: '';
    @include square(100%);
    //border-radius: 100%;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transition: -webkit-transform, $transition-duration, cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: -moz-transform, $transition-duration, cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform, $transition-duration, cubic-bezier(0.4, 0, 0.2, 1);
  }
  .search-form {
    @include vertical-align($header-height, 2.125rem);
    position: relative;
    padding-left: 0;
    padding-right: 0;
    .search-input {
      border: 0;
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.main-panel > .header .icon-toggle-sidebar:before{
  content: "\e07a";
  font-family: 'Simple-Line-Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.layout-small-menu .main-panel > .header .icon-toggle-sidebar:before{
  content: "\e079";
}

@media screen and (min-width: $screen-sm-min) {
  .header.search-open {
    @include box-shadow(0 0 0.0625rem rgba(0, 0, 0, 0.1));
    &::before {
      @include square(100%);
      @include rounded(0);
      display: inline-block;
      background: #fff;
    }
    a, .navbar-text {
      color: $text-color!important;
    }
    a:hover,
    a:focus {
      background-color: transparent!important;
    }
  }
  .search-predict {
    position: absolute;
    top: 1.6rem;
    margin-top: $padding-base;
    min-width: 20rem;
    background-color: #fff;
    @include box-shadow(0 0.125rem 0.25rem rgba( 50, 70, 75, .2 ));
    padding: 0.625rem 1.25rem;
    z-index: 1;
    @include rounded(0.125rem);
    &::before {
      position: absolute;
      top: -1rem;
      left: $padding-base;
      @include square(0);
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent transparent rgba( 50, 70, 75, .2 ) transparent;
      content: " ";
      pointer-events: none;
    }
    &::after {
      position: absolute;
      top: -0.875rem;
      left: ($padding-base + 0.0625rem);
      @include square(0);
      border-width: 0.4375rem;
      border-style: solid;
      border-color: transparent transparent white transparent;
      content: " ";
      pointer-events: none;
    }
    .heading {
      text-transform: uppercase;
      font-size: 0.6875rem;
      margin-top: 1rem;
      padding: 0.3125rem $padding-base;
      background-color: $border-color;
      margin-left: -$padding-base;
      margin-right: -$padding-base;
      line-height: 1
    }
    .predictive-list {
      list-style: none;
      padding: 0;
      margin: 1rem 0 0;
      a {
        padding: 0.3125rem 0;
        display: block;
      }
      img {
        @include square(2rem);
        display: inline-block;
        vertical-align: middle;
      }
      span {
        line-height: 2rem;
        padding-left: 1rem;
      }
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  .main-panel > .header {
    position: relative;
    width: 100%;
    .navbar-form {
      @include vertical-align($header-height, 2.125rem);
    }
  }
  .navbar-nav.navbar-right:last-child {
    margin-right: 0;
  }
  .main-panel > header .dropdown-menu {
    padding: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .main-panel > .header {
    width: 100%;
    .navbar-nav .open .dropdown-menu {
      position: absolute;
      left: auto;
      right: 0;
    }
    > .navbar-nav {
      float: left;
    }
    > .navbar-right {
      float: right;
    }
    > .nav > li {
      float: left;
    }
  }
}

/* default header skin */
@include header-variant($header-skin-background, $header-skin-hover-background, $header-skin-color, $header-skin-hover);

