/* $print
 ------------------------------------------*/

@media print {
  .main-content {
    width: 100%;
  }
  .sidebar-panel,
  .content-footer,
  .chat-panel,
  .header,
  .no-print {
    display: none;
  }
}
